import React, { useEffect } from 'react';
import styles from './toast.module.css';

const Toast = ({ type, message, onClose }) => {
  useEffect(() => {
    //Auto-dismiss toast after specified duration
    const timer = setTimeout(() => {
      onClose();
    }, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);
  return (
    <>
      {type === 'success' ? (
        <div className={styles.successToastArea}>
          <div className="ml-2">{message}</div>
        </div>
      ) : (
        <>
          {type === 'error' ? (
            <div className={styles.errorToastArea}>
              <div className="ml-2">{message}</div>
            </div>
          ) : (
            <div className={styles.infoToastArea}>
              <div className="ml-2">{message}</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Toast;